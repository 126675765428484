@import url(https://fonts.googleapis.com/css?family=Nunito:400,400i,600,700&display=swap&subset=latin-ext);
@import "vars";
@import "color";
@import "button";
@import "typography";
@import "dropdown-menu";
@import "drawer";
@import "avatar";
@import "modal";
@import "navigation";
@import "rtl";
@import "dark";
@import "membership";
@import "responsive";

#root{
  display: flex;
  @media screen and (max-width: 991px) {
    // display: block; 
  }
}


.tab-content {
	padding-top: 30px;

	.form-item {
		margin-bottom: .8rem;
	}
}

.layout {
  flex: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;

	.content {
		padding: 30px;
		display: flex;
		flex: 1;

		@import "sidebar";

		@import "chat";
	}
}

.spinner-box{
  text-align: center;
  position: absolute;
  width: 100%;
  color: black;
  height: 100%;
  margin-top: -50px;
  background-color: #ffffffa8;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  i{
    height: fit-content;
  }
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.selected-contact{
  border: 3px solid var(--success) !important;
  border-radius: 5px;
  padding-top: 0;
}

.epr-emoji-category-label{
  text-transform: none !important;
}

.channel-box{
  overflow-x: hidden;
  margin: 0 auto;
}

#overlay{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.table thead th{
  border: 0;
}

.table{
  margin: 0;
}

.channel-table{
  border: 1px solid #dee2e6;
  border-radius: 25px;
  margin-bottom: 20px;
}

.styleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  .style-col-top {
    display: flex;
  }
  .style-col-bottom {
    display: flex;
    justify-content: space-between;
  }
}

.dropdown-channel{
  left: 125px !important;
  top: 25px !important;
}

.transfer-info-box{
  border: 2px solid lightgray;
  width: 90%;
  padding: 10px 0;
  margin: 0 auto;
  border-radius: 5px;
}

.overlay-loading-customers{
  width: 100%;
  height: 100%;
  background-color: #ffffffa8;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiselect__placeholder, .multiselect__single-value{
  text-align: left;
  font-weight: 500;
  font-size: 16px;
}

.multiselect__indicator-separator{
  display: none;
}

.multiselect__dropdown-indicator{
  color: #495057;
  scale: 0.8;
}

.multiselect2__placeholder {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
}

.multiselect2__indicator-separator{
  display: none;
}

.multiselect2__dropdown-indicator{
  color: #495057;
  scale: 0.8;
}

.phone-input{
  display: flex;
  align-items: center;
}

.phone-list{
  position: absolute;
  background-color: white;
  z-index: 2;
  height: 200px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  border-radius: 5px;
  margin-top: 5px;
  display: block;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  }

  .phone-item-list{
    margin: 5px 0;
    display: flex;
    cursor: pointer;
  }

  .react-international-phone-flag-emoji{
    margin-right: auto;
    margin-left: 20px;
    display: block;
  }

  .react-international-phone-country-selector-dropdown__list-item-country-name{
    margin: auto;
  }

  .react-international-phone-country-selector-dropdown__list-item-dial-code{
    margin-right: 5px;
    margin-left: auto;
  }
}

.img-viewer-container{
  z-index: 9999 !important;
}

.message-date {
  margin: 0 auto;
  
  .message-content{
    box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.fui-dropzone-root {
  border: none !important;
  width: 100%;
  min-width: auto !important;
  min-height: auto !important;
  position: initial !important;
  display: block !important;
  flex-direction: initial !important;
  gap: 0 !important;
  text-rendering: initial !important;
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
  text-align: initial !important;
  font-weight: initial !important;
  letter-spacing: initial !important;
  box-sizing: border-box !important;
  word-break: initial !important;
  color: initial !important;
}

.files-ui-dropzone-children-container {
  height: 100%;
}

.files-ui-disabled-layer-color-default {
  display: none;
}

.btn-modal-close {
  position: absolute;
  right: 40px;
  top: 20px;
  z-index: 2;
}

.custom-switch .custom-control-label::before {
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  cursor: pointer;
}

.input-customers {
  background: url(../img/lupa.png) no-repeat scroll 7px 7px;
  padding-left: 30px;
  background-size: 20px;
  background-color: #ebebeb;
  &:focus{
    background-color: #ebebeb;
  }
}

.input-calendar {
  cursor: pointer;
  background-size: 20px;
  background-color: #ebebeb;
  &:focus{
    background-color: #ebebeb;
  }
}

.tbody {
  border-top: none !important;
  box-shadow: 0 0 0 1px transparent;
  
  td {
    border: none;
    vertical-align: middle;
    max-width: 150px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr:hover{
    transition: all 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 20px 20px -20px;
  }

  tr{
    cursor: pointer;
    background-color: #d4d5d8;
    transition: .5s all ease;
  }
}

.tbodyCampaign {
  @extend .tbody;
  td {
    max-width:500px;

  }
}

.new-class td {
  max-width: 150px; /* Herda a propriedade de .tbody */
}

.tbody-departments {
  border-top: none !important;
  box-shadow: 0 0 0 1px transparent;
  
  td {
    border: none;
    vertical-align: middle;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr:hover{
    @extend .bg-primary;
  }

  tr{
    cursor: pointer;
    background-color: #d4d5d8;
    transition: .5s all ease;
  }
}

.tbody-tags {
  border-top: none !important;
  box-shadow: 0 0 0 1px transparent;
  
  td {
    border: none;
    vertical-align: middle;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr:hover{
    @extend .bg-primary;
  }

  tr{
    cursor: pointer;
    background-color: #d4d5d8;
    transition: .5s all ease;
  }
  
  .fa-tag{
    padding: 5px 6px;
    border-radius: 10px;
    background-color: #e6e6e6;
  }
}

.thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}

.thead-departments {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.status-icon{
  position: absolute;
  left: 3px;
  top: 10px;
  padding: 3px 4px 4px;
  border-radius: 10px;
  font-size: 10px;
}

.react-calendar{
  position: absolute;
  top: 50px;

  z-index: 999;
}

.arrow-calendar{
  border: 1px solid $color-primary;
  padding: 10px 11px;
  color: $color-primary;
  cursor: pointer;
  -webkit-text-stroke: 1px white;
}

.grid-cards{
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.box-table-dashboard{
  height: 550px;
  @media screen and (max-width: 1367px) {
      max-height: 400px !important;
      position: sticky;
      top: 0;
  }
  .table-dashboard{
    .thead-dashboard{
      position: sticky;
      top: 0;
      background-color: rgba(255, 255, 255, 0.859);
    }
    .tbody-dashboard td{
      border-top: 0;
    }
  }
}
  
// .name-table{
//   word-wrap: break-word;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-width: 200px;
//   white-space: nowrap;
// }

.echarts-for-react{
  width: 80%;
  height: 450px;
  position: sticky !important;
  z-index: 2;
  top: 0 !important;
  // @media screen and(max-width: 1366px) {
  //   top: -70px !important;
  //   scale: 0.9;
  // }
}

@media screen and (max-width: 1367px) and (min-width: 1200px) {
  .layout .content .chat .chat-header .chat-header-user h5{
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
}

#add-wallet span {
  background: url(../img/wallet.png) no-repeat;
  @extend .btn
}

.wallet-switch input, .wallet-switch+label{
  cursor: pointer;
  margin: 0;
  text-align: center;
}

.badge{
  color: black !important;
  background-color: #e6e6e6 !important;
}

.input-tags div:first-child{
  gap: 5px;
}

.react-tagsinput{
  width: 100%;
  border-radius: 5px;
  &--focused{
    border: #28a745 2px solid !important;
  }
  span{
    border-radius: 5px;
    display: flex;
    gap: 10px;
    color: black;
    input{
      flex: 1;
    }
  }
  .react-tagsinput-tag{
    background-color: #e6e6e6;
    border: 1px solid #28a745;
  }
}

.text-black{
  color: black;
}

.hide-rail-x.ps--active-x > .ps__rail-x {
  display: none !important;
}

.toaster{
  z-index: 99999 !important;
}

.form-membership{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.drawer-body{
  margin-left: 70px;
}

.navigation-mobile{
  display: none;
}

.search-fields-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;

}

.search-fields-container.open {
  max-height: 800px; 

}

.rbt-menu .dropdown-item {
  padding: 5px 10px;
}
