nav {
	&.navigation {
		background: white;
    transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

		.nav-group{
      transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.nav-group-closed {
        width: 70px;
        .sub-items-nav {
          i {
            flex-grow: 0;
          }

          span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 80%;
            text-align: left;
          }
        }
      }
      &.nav-group-opened {
        i {
          flex-grow: 1;
        }
        
        .box-sub-items {
          max-width: 50%;
          span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 80%;
            text-align: left;
          }
        }
      }
    }

		ul {
			// height: 100vh;
			// height: calc(var(--vh, 1vh) * 100);
			display: flex;
			flex-direction: column;
			padding: 0;
			margin: 0;
        
      .parent-li {
        display: block;
        text-align: center;
        color: black;
        position: relative;
        transition: all .3s;
        margin: 10px 0;
        cursor: pointer;

        i {
          flex-grow: 1;
        }
        
        +.box-sub-items {
          i {
            text-align: left;
          }
        }
      }

      .parent-li-open {
        i {
          flex-grow: 0 !important;
        }
      }

			li {
        list-style: none;

        &.notify_badge:before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background-color: $color-danger;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          right: 10px;
        }

        &.active {
          color: $color-primary
        }

        &.logo {
          margin-bottom: 1rem;
          background-color: $color-primary;
          padding: 15px 0;

          svg {
            path {
              fill: rgba(white, .7);
            }

            circle {
              fill: rgba(white, .7);
            }
          }

          i {
            font-size: $default-font-size + 12;
            margin: 0 auto;
            flex-grow: 1;
          }

          &:hover, &:focus {
            background-color: $color-primary;
          }
        }

        &:hover, &:focus {
          color: $color-primary
        }

        i {
          font-size: $default-font-size + 5;
        }

        &.brackets {
          margin-top: auto;
        }
      }
		}

    a, p {
      display: block;
      text-align: center;
      padding: 11px 0;
      color: black;
      position: relative;
      transition: all .3s;
      cursor: pointer;
      
      &.notify_badge:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: $color-danger;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      &.active {
        color: $color-primary
      }

      &.logo {
        margin-bottom: 1rem;
        background-color: $color-primary;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        svg {
          path {
            fill: rgba(white, .7);
          }

          circle {
            fill: rgba(white, .7);
          }
        }

        i {
          font-size: $default-font-size + 12;
        }

        &:hover, &:focus {
          background-color: $color-primary;
        }
      }

      &:hover, &:focus {
        color: $color-primary
      }

      i {
        font-size: $default-font-size + 5;
      }
    }
	}
}
