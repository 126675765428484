// .header {
//   min-height: 64px;
//   border-bottom: 1px solid #ddd;
// }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.overlayOpen {
  opacity: 1;
  //z-index: 9999;
  z-index: 800;
  visibility: visible;
}

.overlayHidden {
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.drawer {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 800;
  //z-index: 9999;
  position: fixed;
  // overflow-y: auto;
  flex-direction: column;

  background-color: #ffffff;
  width: 300px;
  flex-shrink: 0;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  }
}

.scroll-items {
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  }
}

.hiddenLeft {
  visibility: hidden;
  width: 240px;
  transform: translateX(-240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.hiddenRight {
  visibility: hidden;
  width: 240px;
  transform: translateX(240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.animate {
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.withoutExtend {
  visibility: visible;
  transform: none;
  width: fit-content;
}

.openLeft {
  left: 0;
  right: auto;
}

.openRight {
  right: 0;
  left: auto;
}

.extendableDrawerClosed {
  opacity: 1;
  z-index: 2;
  visibility: visible;
}

.icon-toggle-drawer {
  color: white;
  font-size: 20px !important;
  margin-top: 10px;
}

.sub-items-nav {
  position: fixed;
  left: 70px;
  background-color: white;
  z-index: 9999;
  padding: 20px;
  margin-top: -30px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 6px -1px 10px -6px rgba(0, 0, 0, 0.2), 6px 0px 5px 2px rgba(0, 0, 0, 0.14), 6px 0px 30px 5px rgba(0, 0, 0, 0.12);
  max-width: 150px;
}