@media screen and (max-width: 1200px) {
  // .channel-box{
  //   width: 70% !important;
  // }
	body:not(.rtl) {
		.sidebar-group {
			position: fixed;
			right: -50%;
			top: 0;
			bottom: 0;
			width: 30%;
			opacity: 0;
			transition: all .2s;
			box-shadow: 25px 0 30px 5px black;
			visibility: hidden;
			z-index: 9;

			.sidebar {
				width: 100% !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
				border-radius: 0 !important;
			}

			&.mobile-open {
				opacity: 1;
				right: 0;
				visibility: visible;
			}
		}
	}
	body.rtl {
		.sidebar-group {
			position: fixed;
			left: -50%;
			top: 0;
			bottom: 0;
			opacity: 0;
			transition: all .2s;
			box-shadow: -25px 0px 30px 5px black;
			visibility: hidden;

			.sidebar {
				width: 100% !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
				border-radius: 0 !important;
			}

			&.mobile-open {
				opacity: 1;
				left: 0;
				visibility: visible;
			}
		}

		.chat + .sidebar-group .sidebar {
			margin-right: 0 !important;
		}
	}
	body.modal-open {
		.sidebar-group {
			right: 0;
		}
	}
}

@media screen and (max-width: 992px) {
	body:not(.rtl), body {
		.sidebar-group {
			width: 45%;
		}
	}
  .channel-box{
    width: 100% !important;
    overflow-x: auto !important;
  }
  .table {
	margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .btn-toggle{
    position: fixed;
    box-shadow: 0px 0 6px 0px black;
    bottom: 50%;
    z-index: 2;
  }

  .left{
    right: 0;
  }

  .navigation-mobile{
    display: block !important;
    position: fixed;
    z-index: 2;
    height: max-content !important;
    box-shadow: 0px 0 6px 0px black;
    img{
      width: 30px;
    }
    p, i{
      margin: 0 !important;
    }
  }

  .navigation-desktop{
    display: none !important;
  }

  .drawer-body{
    margin-left: 0 !important;
  }

  .chat{
    width: 100%;
    .ps__rail-y{
      opacity: 0.3 !important;
    }
    .ps__rail-x{
      display: none !important;
    }
  }

  .chat-body {
    overflow: hidden;
    .scrollbar-container{
      height: 100%;
    }
  }
  
  #root{
    width: 100%;
  }
  
	.sidebar-group {
		.sidebar {
			.list-group-item {
				.action-toggle {
					.dropdown {
						display: block !important;
					}
				}
			}
		}
	}

	body:not(.rtl), body {
		.sidebar-group {
			width: 75%;

			.sidebar {
				.list-group-item {
					.users-list-body h5 {
						font-size: $default-font-size + 7 !important;
					}
				}
			}
		}
	}

  .scrollbar-container{
    height: 70%;
  }

	.layout {
    width: 100%;
		.content {
			padding: 20px !important;
      padding-bottom: 0 !important;
      overflow: hidden;
      height: calc(100vh - 20px);
      width: 100%;

			.chat {
				.chat-header {
					display: block !important;
					padding-bottom: 15px !important;

					.chat-header-user {
						h5 {
							font-size: $default-font-size + 5 !important;
						}
					}

					.chat-header-action {
						margin-top: 15px;
					}
				}

				.chat-body {
					.messages {
						.message-item {
							max-width: 100% !important;
						}
					}
				}
			}
		}
	}

  .nav-group-closed {
    width: 60px !important;
  }

  .transfer-inputs, .transfer-description{
    flex-direction: column;
    button{
      margin: 0 auto;
    }
  }

  .transfer-box{
    padding: 0 20px !important;
  }

  .layout .content{
    height: 90%;
  }

  .modal-content{
    margin: auto;
  }

  .styleRow {
	padding: 0 !important;
	.style-col-top {
		flex-direction: column;
	}
	.style-col-bottom {
		flex-direction: column !important;
		align-items: flex-end;
		button {
			margin-top: 5px;
			width: 50%;
		}
	  }
  }
  .content-header {
	width: 100%;
	flex-direction: column;
  }
  .input-calendar {
	width: 100% !important;
  }

  .index-mobile {
	z-index: 2;
  }
}

@media screen and (max-width: 576px) {
	body:not(.rtl), body {
		.sidebar-group {
      width: calc(100% - 80px);
		}
	}

	body.form-membership {
		padding: 20px;

		.form-wrapper {
			padding-right: 20px;
			padding-left: 20px;
			width: 100% !important;
			margin-bottom: 20px;
		}
	}
  
  .chat-header {
    .chat-header-box{
      flex-direction: column;
      align-items: center;
    }
    
    .text-muted{
      margin: 0 auto;
    }
  }

  .sidebar-body {
	padding: 0 !important;
  }
}

