.sidebar-group {

  display: flex;
  flex-direction: column;

  .sidebar {
    &:not(.active) {
      display: none;
    }

    margin-right: 30px;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    width: 330px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &>header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      height: 80px;
      font-weight: 700;
      border-radius: 0;

      &>span {
        font-size: 18px;
      }

      ul {
        margin-bottom: 0;

        li {
          margin-bottom: 0;
        }

        a {}
      }
    }

    &>form {
      padding: 10px 20px;
      margin-bottom: 1rem;

      input[type="text"] {
        border-radius: 5px;
        border: none;
        height: auto;
        ;
        padding: 10px 15px;
        background-color: #ebebeb;

        &:focus {
          box-shadow: none;
        }
      }
    }

    & .sidebar-body {
      flex: 1;
      overflow: auto;
    }

    .list-group-item {
      padding: 20px;
      display: flex;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 5px;
        background-color: $color-primary;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &.open-chat {
        &:before {
          opacity: 1;
        }

        .users-list-body {
          p {
            font-weight: 600;
            color: black0
          }
        }
      }

      figure {
        margin-right: 1rem;
      }

      .users-list-body {
        overflow: hidden;
        flex: 1;
        position: relative;
        min-width: 0px;
        display: flex;
        flex-direction: column;

        .users-info {
          display: flex;
          flex-direction: column;
          gap: 5px;

          small {
            border: 1px solid black;
            width: 80%;
            margin: 0 auto;
            border-radius: 5px;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        .tags-queue {
          display: grid;
          align-items: center;
          justify-content: center;
          gap: 5px;

        }

        h5 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: $default-font-size + 2;
          font-weight: 600;
          margin-bottom: .2rem;
          line-height: 1;
        }

        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 0;
          color: black
        }

        .users-list-action {
          position: absolute;
          right: 0;
          top: 0;
          background: white;
          box-shadow: -8px 1px 10px 5px #ffffff;
          bottom: 0;
          padding: 0 5px;

          [data-toggle="dropdown"],
          .dropdown {
            i {
              font-size: $default-font-size + 4;
              color: $color-primary;
            }
          }

          .new-message-count {
            width: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            font-size: $default-font-size - 2;
            height: 22px;
            background-color: $color-primary;
            color: white;
            border-radius: 50%;
          }

          &.action-toggle .dropdown {
            display: none;
            text-align: center;
          }
        }
      }

      &:hover {
        .users-list-action.action-toggle .dropdown {
          display: block;
        }
      }
    }
  }
}

.new-message-count {
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 12px;
  height: 22px;
  background-color: #6161FF;
  color: white;
  border-radius: 50%;
}

.users-list-action-new {
  z-index: 999;
  position: absolute;
  right: 30px;
  top: 40px;
  background: transparent;
  box-shadow: -8px 1px 10px 5px #ffffff;
  bottom: 0;

  [data-toggle="dropdown"],
  .dropdown {
    i {
      font-size: $default-font-size + 4;
      color: $color-primary;
    }
  }

  .new-message-count {
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    font-size: $default-font-size - 2;
    height: 22px;
    background-color: $color-primary;
    color: white;
    border-radius: 50%;
  }

  &.action-toggle .dropdown {
    display: none;
    text-align: center;
  }
}


.list-group-item:hover {
  .users-list-action-new.action-toggle .dropdown {
    display: block;
  }
}


* {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  }
}