body {
	background-color: #ebebeb;
	font-family: 'Nunito', sans-serif;
	font-size: $default-font-size;

	&:not(.form-membership) {
		overflow: hidden
	}
}

.btn {
	border-color: transparent;
	transition: background-color 0.3s ease;
}

.btn-primary:not([disabled]):hover {
	background-color: brightness($color-primary, 80%); 
  }

.btn-primary {
	background-color: $color-primary;
}

a {
	color: $color-primary;

	&:hover, &:focus {
		text-decoration: none;
		outline: none;
		color: $color-primary;
	}
}

.page-loading {
	position: fixed;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	background-color: white;
	z-index: 999;
}

.btn-icon {
	margin-right: .5rem;
}

.list-group-item {
	background-color: inherit;
	border-color: white;
  .users-list-top{
    display: flex;
    align-items: center;
    border-top: 1px solid lightgray;
    margin-top: -10px;
    padding-top: 10px;
  }
}

ul.list-inline {
	.list-inline-item {
		margin-bottom: .5rem;

		&:not(:last-child) {
			margin-right: 0;
		}
	}

	&:not(.social-links) {
		.list-inline-item {
			margin-left: .6rem;
		}
	}

	&.social-links {
		.list-inline-item {
			margin-right: .5rem;
			margin-bottom: .6rem;
		}
	}
}

.files {
	ul.list-inline {
		display: flex;
		// overflow-x: hidden;

		.list-inline-item {
			margin-left: 0;
			margin-right: .5rem;
		}
	}
}

.connection-error {
	svg {
		margin: 30px 0;
		height: 100px;
		width: 100%;

		path, circle, polygon {
			fill: $color-danger;
		}
	}
}

.form-control {
	border-radius: 5px;

	&:focus {
		border-color: transparent;
		box-shadow: 0 0 0 0.05rem $color-primary;
	}
}

.custom-control-label {
	line-height: 25px;
}

.custom-control-input:checked ~ .custom-control-label::before {
	background-color: $color-primary;
	border-color: $color-primary;
}

.custom-department {
	.custom-control-input:checked ~ .custom-control-label::before {
		background-color: $color-danger;
		border-color: $color-danger;
	}
}
