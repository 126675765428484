.modal-content {
	border-radius: 5px;
	border: none;
	box-shadow: none;
	overflow: hidden;

	.modal-header {
		align-items: center;
		padding: 20px 30px;
		background-color: white;
		border-bottom: none;

		.modal-title {
			display: flex;
			align-items: center;
			line-height: 13px;
			font-weight: 600;

			i, figure.avatar {
				margin-right: .7rem;
				font-size: $default-font-size + 3;
			}

			& + button {
				text-shadow: none;
				font-size: 23px;
				margin: 0;
				background-color: white;
				border-radius: 50%;
				padding: 0;
				width: 30px;
				height: 30px;
			}
		}
	}

	.modal-body {
		padding: 30px;
	}

	.modal-footer {
		border-top: none;
		padding: 30px;
		padding-top: 0;
	}
}

body:not(.no-blur-effect) {
	&.modal-open .layout {
		-webkit-filter: blur(1px);
		-moz-filter: blur(1px);
		-o-filter: blur(1px);
		-ms-filter: blur(1px);
		filter: blur(1px);
	}

	.modal.fade .modal-dialog.modal-dialog-zoom {
		-webkit-transform: translate(0, 0) scale(.5);
		transform: translate(0, 0) scale(.5);
	}

	.modal.show .modal-dialog.modal-dialog-zoom {
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}
}

.call {
	.modal-content {
		background: black;
		color: rgba(white, .6);

		.modal-body {
			padding: 50px 0;
		}
	}

	.call-background {
		background-size: cover !important;
		background-position: center !important;
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		-webkit-filter: blur(1px);
		-moz-filter: blur(1px);
		-o-filter: blur(1px);
		-ms-filter: blur(1px);
		filter: blur(1px);
		opacity: .2;

		& + div {
			position: relative;
			z-index: 1;
			text-align: center;

			.action-button {
				margin-top: 3rem;

				.btn {
					font-size: $default-font-size + 12;
					width: 70px;
					height: 70px;
					margin: 0 20px;
				}
			}
		}
	}
}
